import {
    REGEX_USERNAME_FORMAT,
    REGEX_PASSWORD_FORMAT,
    REGEX_EMAIL_FORMAT,
    REGEX_CUSTOM_TAG_FORMAT,
} from '@/utils/Regex'

import t from 'helpers/translation/getTranslation'

export const LOGIN_EMAIL_VALIDATION = {
    required: {
        value: true,
        message: t('forms.emailInvalid') as string,
    },
    maxLength: {
        value: 255,
        message: t('forms.emailInvalid') as string,
    },
}

export const LOGIN_PASSWORD_VALIDATION = {
    required: {
        value: true,
        message: t('forms.passowrdRequired') as string,
    },
    maxLength: {
        value: 255,
        message: t('forms.passwordInvalid') as string,
    },
}

export const USERNAME_VALIDATION = {
    required: {
        value: true,
        message: t('forms.usernameRequired') as string,
    },
    maxLength: {
        value: 32,
        message: t('forms.usernameLength') as string,
    },
    minLength: {
        value: 2,
        message: t('forms.usernameLength') as string,
    },
    pattern: {
        value: REGEX_USERNAME_FORMAT,
        message: t('forms.usernameFormat') as string,
    },
}

export const EMAIL_VALIDATION = {
    required: {
        value: true,
        message: t('forms.emailRequired') as string,
    },
    pattern: {
        value: REGEX_EMAIL_FORMAT,
        message: t('forms.emailInvalid') as string,
    },
}

export const PASSWORD_VALIDATION = {
    required: {
        value: true,
        message: t('forms.passowrdRequired') as string,
    },
    pattern: {
        value: REGEX_PASSWORD_FORMAT,
        message: t('forms.passwordFormat') as string,
    },
}

export const CONFIRM_PASSWORD_VALIDATION = {
    required: {
        value: true,
        message: t('forms.passwordConfirmationRequired') as string,
    },
}

export const GAME_TESTER_VALIDATION = {
    required: {
        value: true,
        message: t('forms.gameTesterRequired') as string,
    },
}

export const CUSTOM_TAG_VALIDATION = {
    required: {
        value: true,
        message: t('forms.customTagRequired') as string,
    },
    maxLength: {
        value: 15,
        message: t('forms.customTagLength') as string,
    },
    minLength: {
        value: 2,
        message: t('forms.customTagLength') as string,
    },
    pattern: {
        value: REGEX_CUSTOM_TAG_FORMAT,
        message: t('forms.customTagFormat') as string,
    },
}
