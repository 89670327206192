import React, { useState } from 'react'

import { Alert, Link, Typography } from '@/components/ui';
import Button from '@/components/ui/Button';
import useWallet, { SupportedWalletType, checkIsMobile, getWalletIcon, isUserRejectedError } from 'hooks/useWallet';
import t from 'helpers/translation/getTranslation';

import WalletName from './WalletName';
import { ConnectWalletModalProps } from './types';

import s from './styles.module.css'

/**
 * Displays a list of wallets 
 * @param props 
 * @returns 
 */
const ConnectWallet = (props: ConnectWalletModalProps) => {
  const { onConnectSuccess, onConnectFailed, supportedWallets = [] } = props
  const [connecting, setConnecting] = useState<string | undefined>()
  const isMobile = checkIsMobile()

  const { connect, getAvailableWallets, connectError, isWalletInstalled, } = useWallet({
    debugId: 'connectWallet'
  })

  const availableWallets = getAvailableWallets(supportedWallets)

  let errorMessage

  if (connectError) {
    if (!isUserRejectedError(connectError.message))
      errorMessage = connectError.message
  }

  const handleOnSuccess = (wallet: SupportedWalletType) => {
    setConnecting(undefined)
    console.log(wallet)
    onConnectSuccess?.(wallet)
  }

  const handleOnFail = (error: Error) => {
    setConnecting(undefined)
    onConnectFailed?.(error)
  }

  return (
    <>
      {
        errorMessage && <Alert center type='error'>{errorMessage}</Alert>
      }
      {
        !isMobile && availableWallets.length === 0 &&
        <Alert center type='error'>
          <p>{t("errors.walletNoWalletsInstalled")}</p>
          <p className="mt-2"><Link target="_blank" href="https://docs.cardano.org/new-to-cardano/types-of-wallets/">Click here to read more about wallets.</Link></p>
        </Alert>
      }
      <div className={s.wallets}>
        {
          availableWallets.map((wallet) => {
            const { name, displayName, mobileEnabled, desktopEnabled = true } = wallet
            const installed = isWalletInstalled(wallet)

            if (isMobile && !mobileEnabled || !isMobile && !desktopEnabled)
              return false

            return (
              <Button
                key={name}
                disabled={!installed || !!connecting}
                isLoading={connecting === name}
                onClick={installed ?
                  () => {
                    setConnecting(name)
                    connect(wallet, handleOnSuccess, handleOnFail)
                  }
                  : undefined
                }
                className={s.connect_wallet}>
                <WalletName icon={getWalletIcon(name)} name={displayName} />
                {
                  !installed &&
                  <Typography className={s.wallet_not_install}>{t("pages.wallet.notInstalled")}</Typography>
                }
              </Button>
            )
          })
        }
      </div>
    </>
  )
}

export default ConnectWallet