import React from 'react'

import { StyledButton, Modal } from '@/components/ui'
import { t } from 'helpers/translation'
import { SupportedWalletType } from 'hooks/useWallet'

import { WalletConnectModalProps } from './types'
import ConnectWallet from '../ConnectWallet'

const WalletConnectModal = (props: WalletConnectModalProps) => {
  const { buttonLabel, modalTitle, buttonProps, supportedWallets, onConnectSuccess } = props

  return (
    <Modal
      title={modalTitle || t("wallet.selectWallet")}
      size="md"
      trigger={(setIsOpen, isOpen) => (
        <StyledButton
          variant="filled"
          size="md"
          color="primary"
          {...buttonProps}
          onClick={() => setIsOpen(!isOpen)}>
          {buttonLabel || t("wallet.connect")}
        </StyledButton>
      )}>
      {
        (setOpen) => (
          <ConnectWallet
            supportedWallets={supportedWallets}
            onConnectSuccess={(wallet: SupportedWalletType) => {
              setOpen(false)

              if (onConnectSuccess) onConnectSuccess(wallet)
            }}
          />
        )
      }
    </Modal>
  )
}

export default WalletConnectModal