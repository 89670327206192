import React, { useCallback, useState } from 'react'
import { useSession } from 'next-auth/react'
import { format } from 'date-fns'

import { DoubleArrow } from '@/components/ui/Icon'
import { Button, Divider, Html, LoadingWrapper, Tooltip, Typography } from '@/components/ui'
import useWallet, { WALLET_CHAIN_TYPE_CARDANO, getWalletIcon } from 'hooks/useWallet'
import { StyledRadioButton, StyledRadioButtonGroup, ValidationSummary } from '@/components/ui/Form'
import { useAuth } from '@/providers/AuthenticationProvider'
import { PlayerHttpClient } from '@/network/httpClients'
import { t } from 'helpers/translation'

import WalletName from '../WalletName';
import WalletAssets from './WalletAssets'
import WalletAddress from './WalletAddress'
import { WalletApiError } from '../types'

import { WalletOnVerifyPayload, WalletOnVerifySuccessPayload, WalletOnVerifyHardwareSuccessPayload, WalletProps } from './types';
import s from './styles.module.css'

/**
 * Displays a list of wallets 
 * @param props 
 * @returns 
 */
const Wallet = (props: WalletProps) => {
  const { walletName, verifyingAddresses, displayName, chain, assets, address, verfiedAddresses, paymentAddresses, isLoading, onReset, onVerifySuccess, onVerifyHardwareSuccess } = props
  const { data: session } = useSession()
  const { user } = useAuth()
  const [retrievingChallenge, setRetrievingChallenge] = useState<boolean>(false)
  const [useHardwareWallet, setUseHardwareWallet] = useState<boolean>(false)
  const [showHWDisclaimer, setShowHWDisclaimer] = useState<WalletOnVerifyPayload | null>(null)
  const { signMessage, cardanoWallet } = useWallet()
  const [error, setError] = useState<WalletApiError>()
  const walletAddress = Array.isArray(address) ? address : [address]

  const onVerify = useCallback(async (payload: WalletOnVerifyPayload) => {
    const { address: addressToVerify, captchaToken } = payload

    // Use alternate verification process for Cardano hardware wallet
    if (useHardwareWallet && chain === WALLET_CHAIN_TYPE_CARDANO) {
      setShowHWDisclaimer(payload)

      return
    }

    const currentDateTime = format(new Date(), "yyyy-MM-dd'T'HH:mm:ssXXX")
    const message = t("wallet.walletVerifyMessage", user?.username, currentDateTime)

    signMessage(
      chain,
      message,
      // Stake address for Cardano, Address for EVM
      addressToVerify,
      (signature: string, key: string | undefined) => {

        const verifyWalletRequest: WalletOnVerifySuccessPayload = {
          walletName,
          address: addressToVerify,
          chain: chain ?? WALLET_CHAIN_TYPE_CARDANO,
          cardanoPaymentAddresses: paymentAddresses,
          unsignedMessage: message,
          signedMessage: signature,
          cardanoPublicKey: key,
          captchaToken,
        }

        onVerifySuccess?.(verifyWalletRequest)
      },
      (err: unknown) => {
        if ((err as WalletApiError).code === 4)
          setError(err as WalletApiError)
      }
    )
  }, [useHardwareWallet, walletName, paymentAddresses, chain, onVerifySuccess, signMessage, user?.username])

  // Cardano only!
  const onVerifyHardwareWallet = useCallback(async () => {
    try {
      if (!showHWDisclaimer || chain !== WALLET_CHAIN_TYPE_CARDANO) return

      setRetrievingChallenge(true)

      const { address: addressToVerify, captchaToken } = showHWDisclaimer
      const currentDateTime = format(new Date(), "yyyy-MM-dd'T'HH:mm:ssXXX");
      const { data: challenge, error: challengeError } = await PlayerHttpClient.setAccessToken(session?.accessToken).GetCardanoHardwareChallengeTransaction(session?.user.id as number, addressToVerify, currentDateTime)

      if (challengeError && challengeError?.length > 0) {
        setShowHWDisclaimer(null)
        setRetrievingChallenge(false)
        setError({
          info: challengeError[0].message,
          // Code isn't used, value doesn't matter here
          code: 500
        })

        return
      }

      if (!challenge) {
        setShowHWDisclaimer(null)
        setRetrievingChallenge(false)
        setError({
          info: 'TX creation failed',
          // Code isn't used, value doesn't matter here
          code: 500
        })

        return
      }

      // Clear disclaimer data to reset view
      await cardanoWallet.signTx(challenge.tx, true, (signature) => {
        if (signature) {
          const verifyWalletRequest: WalletOnVerifyHardwareSuccessPayload = {
            walletName,
            address: addressToVerify,
            chain: chain ?? WALLET_CHAIN_TYPE_CARDANO,
            cardanoPaymentAddresses: paymentAddresses,
            verificationSignature: signature,
            verificationTx: challenge.tx,
            captchaToken
          }

          onVerifyHardwareSuccess?.(verifyWalletRequest)
        }
      })
      setShowHWDisclaimer(null)
      setRetrievingChallenge(false)
    } catch (exception) {
      // Clear disclaimer data to reset view
      setShowHWDisclaimer(null)
      setRetrievingChallenge(false)

      if (typeof exception === "string")
        setError({
          info: exception,
          // Code isn't used, value doesn't matter here
          code: 500
        })
      else
        setError(exception as WalletApiError)
    }
  }, [showHWDisclaimer, chain, cardanoWallet, onVerifyHardwareSuccess, paymentAddresses, session?.accessToken, session?.user.id, walletName])

  return (
    <>
      <div className={s.wallet_assets_header}>
        <WalletName icon={getWalletIcon(walletName)} name={displayName} />
        <Button onClick={onReset}>
          <DoubleArrow className={s.back} rotation='90' /> {t("wallet.back")}
        </Button>
      </div>
      {error && <ValidationSummary errors={{ root: { message: error.info } }} />}
      {
        showHWDisclaimer ?
          <div className={s.hardware_wallet_disclaimer}>
            <Typography
              as="h4"
              className={s.title}
              uppercase>
              {t("wallet.hardwareWalletDisclaimer.title")}
            </Typography>
            <Typography>
              <Html html={t("wallet.hardwareWalletDisclaimer.description")} />
            </Typography>
            <div className={s.actions}>
              <Button
                variant="default"
                outline
                onClick={() => setShowHWDisclaimer(null)}>
                {t("wallet.hardwareWalletDisclaimer.actionCancel")}
              </Button>
              <Button
                filled
                isLoading={retrievingChallenge}
                variant="primary"
                onClick={() => onVerifyHardwareWallet()}>
                {t("wallet.hardwareWalletDisclaimer.actionProceed")}
              </Button>
            </div>
          </div>
          :
          <LoadingWrapper
            message={t("wallet.searchingForAssets")}
            isLoading={isLoading}>
            {
              walletAddress.map(addr => (
                <WalletAddress
                  key={addr}
                  address={addr}
                  walletName={walletName}
                  isVerifying={verifyingAddresses[addr] ?? false}
                  chain={chain}
                  paymentAddresses={paymentAddresses}
                  verfiedAddresses={verfiedAddresses}
                  onVerify={onVerify}
                  onError={setError}
                  useHardwareWallet={useHardwareWallet}
                />
              ))
            }
            {
              chain === WALLET_CHAIN_TYPE_CARDANO &&
              <>
                <Divider />
                <div className={s.hardware_wallet}>
                  <div className={s.hardware_wallet_label}>
                    <Typography>
                      {t("wallet.userUsingHardwareWallet")}
                    </Typography>
                    <Tooltip size="sm">
                      {t("wallet.hardwareWalletModeExplanation")}
                    </Tooltip>
                  </div>
                  <StyledRadioButtonGroup className={s.hardware_wallet_radio_button}>
                    <StyledRadioButton
                      title={t("wallet.turnHardwareWalletModeOn")}
                      onChange={() => { setUseHardwareWallet(true) }}
                      id='hardware_wallet_on'
                      name="hardware_wallet_mode"
                      checked={useHardwareWallet === true}
                      size="sm"
                      value="on">
                      {t("wallet.hardwalletModeOn")}
                    </StyledRadioButton>
                    <StyledRadioButton
                      title={t("wallet.turnHardwareWalletModeOff")}
                      onChange={() => { setUseHardwareWallet(false) }}
                      id='hardware_wallet_off'
                      name="hardware_wallet_mode"
                      className=""
                      checked={useHardwareWallet === false}
                      size="sm"
                      value="off">
                      {t("wallet.hardwalletModeOff")}
                    </StyledRadioButton>
                  </StyledRadioButtonGroup>
                </div>
              </>
            }
            {
              /* <Divider />
              <WalletAssets
                assets={assets} /> */
            }

          </LoadingWrapper>
      }
    </>
  )
}

export default Wallet
