import React from 'react'

import Typography from '@/components/ui/Typography';
import Image from '@/components/ui/Image';

import { WalletNameProps } from './types';

import s from './styles.module.css'

/**
 * Show wallet name and icon
 * @param props 
 * @returns 
 */
const WalletName = (props: WalletNameProps) => {
  const { name, icon } = props

  return (
    <div className={s.wallet}>
      <Image
        className={s.wallet_icon}
        width={48}
        height={48}
        title={name}
        alt={name}
        src={icon} />
      <Typography className={s.wallet_name}>{name}</Typography>
    </div>
  )
}

export default WalletName