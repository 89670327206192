import React, { useRef, useMemo } from 'react'
import HCaptcha from "@hcaptcha/react-hcaptcha"

import { Button, Typography } from '@/components/ui'
import obfuscateString from 'helpers/obfuscateString'
import { WALLET_CHAIN_TYPE_CARDANO, WALLET_CHAIN_TYPE_EVM } from 'hooks/useWallet'
import t from 'helpers/translation/getTranslation'

import { WalletAddressProps } from './types';
import s from './styles.module.css'

const NEXT_PUBLIC_HCAPTCHA_KEY = process.env.NEXT_PUBLIC_HCAPTCHA_KEY ?? ""

/**
 * Displays a single wallet address
 * @param props 
 * @returns 
 */
const WalletAddress = (props: WalletAddressProps) => {
  const { walletName, chain, address, isVerifying = false, verfiedAddresses = [], paymentAddresses = [], onVerify, onError, useHardwareWallet } = props

  const captchaRef = useRef<HCaptcha>(null)

  // Get a list of unsaved payment addresses to be saved (CARDANO ONLY)
  const unsavedPaymentAddresses = useMemo(() => paymentAddresses.filter(addr => !verfiedAddresses.includes(addr)), [paymentAddresses, verfiedAddresses])
  const hasNewAddresses = unsavedPaymentAddresses.length > 0
  // Determine whether a wallet has been saved previously to decide what text the save button shows
  // If EVM, check if address is in verified list
  // If Cardano, check if the stake address is still saved and if any payment addresses exist, if stake address isn't on the list has been removed but the payment addresses remain until page refresh.
  const hasSavedAddresses = chain === WALLET_CHAIN_TYPE_EVM ? verfiedAddresses.includes(address) : paymentAddresses.some(addr => verfiedAddresses.includes(addr)) && verfiedAddresses.includes(address)

  /**
   * Before saving -> trigger captcha validation process
   */
  const onCaptchaExecute = () => {
    captchaRef.current?.execute()
  }

  const resetError = () => onError(undefined)

  /**
   * On verify -> trigger signing process
   */
  const handleOnVerify = async (captchaToken: string) => {
    if (onVerify) {
      resetError()
      onVerify({
        walletName,
        chain: chain ?? WALLET_CHAIN_TYPE_CARDANO,
        address,
        paymentAddresses,
        useHardwareWallet,
        captchaToken
      })
    }
  }

  return (
    <div className={s.wallet_address}>
      {
        address &&
        <Typography as="p" className={s.wallet_address_label}>
          <span className="text-sm pr-1">{t("pages.wallet.account")}</span>
          <strong>{obfuscateString(address, 5)}</strong>
          {
            // chain === WALLET_CHAIN_TYPE_CARDANO &&
            // <Tooltip
            //   size="sm">
            //   {t("wallet.whatIsStakeAddress")}
            // </Tooltip>
          }
        </Typography>
      }
      <Button
        variant="primary"
        className={s.connect}
        isLoading={isVerifying}
        disabled={hasSavedAddresses && !hasNewAddresses}
        filled
        onClick={onCaptchaExecute}
      >
        {
          // eslint-disable-next-line no-nested-ternary
          !hasSavedAddresses ? t("pages.settings.walletVerficationLinkButton") : hasNewAddresses ? 'Sync' : t('pages.settings.walletVerficationLinkedButton')
        }
      </Button>
      <HCaptcha
        sitekey={NEXT_PUBLIC_HCAPTCHA_KEY}
        size="invisible"
        onVerify={handleOnVerify}
        ref={captchaRef}
      />
    </div>
  )
}

export default WalletAddress
